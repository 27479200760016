import { useTranslations } from 'next-intl'

import { LOGIN_PATH, REGISTRATION_PATH } from '../../paths'
import { Link } from '@/components/link/link'

export const GuestPopupMenuItems = () => {
  const t = useTranslations('Header')

  return (
    <>
      <Link
        href={LOGIN_PATH}
        className="flex px-2 w-full items-center hover:bg-grey-100 h-[34px]"
      >
        {t('login')}
      </Link>
      <Link
        href={REGISTRATION_PATH}
        className="flex px-2 w-full items-center hover:bg-grey-100 h-[34px]"
      >
        {t('registration')}
      </Link>
    </>
  )
}
