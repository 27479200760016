/* eslint-disable @next/next/no-img-element */
import { memo } from 'react'

import { Link } from '@/components/link/link'
import { AutocompletePopupItemHighlighted } from './AutocompletePopupItemHighlighted'
import type { Item } from '../types'

type Props = {
  id: string
  label: string
  url: string
  img?: string
  query: string
  isFocused: boolean
  onSubmit: (focusedPopupItem: Item) => void
}

function AutocompletePopupItem({
  id,
  label,
  url,
  img,
  query,
  isFocused,
  onSubmit,
}: Props) {
  return (
    <li
      className="px-2 py-1 text-xs hover:underline aria-selected:underline"
      role="option"
      aria-selected={isFocused}
      id={id}
    >
      <Link
        className="flex w-full items-center justify-start gap-2"
        href={url}
        onClick={(e) => {
          e.preventDefault()
          onSubmit({
            id,
            label,
            url,
          })
        }}
      >
        {img && (
          <div
            className="w-[68px] h-[68px] bg-contain bg-center bg-no-repeat"
            style={{
              backgroundImage: `url(${img})`,
            }}
          />
        )}
        {query && (
          <AutocompletePopupItemHighlighted label={label} query={query} />
        )}
        {!query && label}
      </Link>
    </li>
  )
}

export default memo(AutocompletePopupItem)
