/* eslint-disable @next/next/no-img-element */
import { useState, useRef, useEffect, memo } from 'react'
import { useTranslations } from 'next-intl'
import Image from 'next/image'

import { selectors } from '@/common/constants/selectors-constants'
import { SearchIcon } from '@/components/icons/svg'
import { DELAY_INPUT_CHANGE_MS, SEARCH_POPUP_ID } from '../constants'
import {
  isSearchLoading,
  isSearchDisabled,
} from '../helpers/autocomplete.utils'
import LoaderGif from '@/assets/loader.gif'
import type { ChangeEvent, KeyboardEvent } from 'react'
import type { Item, SearchState } from '../types'

type TimeoutIdType = NodeJS.Timeout | string | number | undefined

type Props = {
  isFocused: boolean
  state: SearchState
  focusedPopupItem?: Item
  onFocus: (isFocused: boolean) => void
  onChange: (input: string) => void
  onSubmit: () => void
}

function AutocompleteInput({
  isFocused,
  state,
  focusedPopupItem,
  onFocus,
  onChange,
  onSubmit,
}: Props) {
  const timeoutIdRef = useRef<TimeoutIdType>(undefined)
  const [value, setValue] = useState('')
  const t = useTranslations('Header')
  const isLoading = isSearchLoading(state)
  const isDisabled = isSearchDisabled(state)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value ?? ''

    setValue(value)

    if (focusedPopupItem) {
      onChange(value)
    }

    timeoutIdRef.current && clearTimeout(timeoutIdRef.current)

    timeoutIdRef.current = setTimeout(() => {
      onChange(value)
    }, DELAY_INPUT_CHANGE_MS)
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (value && e.code === 'Enter') {
      onSubmit()
    }
  }

  useEffect(() => {
    return () => {
      clearTimeout(timeoutIdRef.current)
    }
  }, [])

  return (
    <>
      <input
        className="h-7 w-full border-2 border-black px-2 text-sm outline-none disabled:border-secondary"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onFocus={() => onFocus(true)}
        value={value}
        disabled={isDisabled}
        placeholder={
          isFocused ? t('search.placeholderFocused') : t('search.placeholder')
        }
        role="combobox"
        aria-expanded={isFocused}
        aria-label="Search"
        aria-controls={SEARCH_POPUP_ID}
        aria-haspopup="listbox"
        aria-activedescendant={focusedPopupItem?.id ?? ''}
        autoComplete="off"
        data-test={selectors.common.autocomplete.input}
      />
      <button
        className="z-20 flex w-7 h-7 items-center justify-center bg-black text-white outline-none ring-secondary focus-visible:ring-2 disabled:bg-secondary aria-disabled:cursor-default"
        disabled={isDisabled}
        aria-disabled={!value || isDisabled}
        onClick={() => onSubmit()}
        onFocus={() => onFocus(false)}
        aria-label="Search"
      >
        <SearchIcon width={16} height={16} className="w-5 h-5" />
      </button>
      <Image
        className="absolute right-14 top-[10px] w-4 h-4 aria-hidden:hidden"
        src={LoaderGif}
        alt="Loading"
        aria-hidden={!isLoading}
        unoptimized
      />
    </>
  )
}

export default memo(AutocompleteInput)
