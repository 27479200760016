'use client'

import React, { useLayoutEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { MobileNavigationItems } from './mobile-navigation-items'
import { useHeaderContext } from '../../header-context'
import { MobileHeaderControls } from '../../header-controls-mobile'
import { HeaderNavigationProps } from '../navigation-types'

const HEADER_CONTROLS_HEIGHT = 'h-[calc(100%-3rem)]'

export const HeaderMobileNavigation = ({ items }: HeaderNavigationProps) => {
  const {
    state: { isMenuOpen },
    actions: { setIsMenuOpen },
  } = useHeaderContext()
  const onClose = () => setIsMenuOpen(false)

  const elRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(0)

  useLayoutEffect(() => {
    const resizeHandler = () => {
      setHeight(
        window.innerHeight - (elRef.current?.getBoundingClientRect().top ?? 0),
      )
    }

    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [isMenuOpen])

  return (
    <div
      ref={elRef}
      onClick={onClose}
      style={{ height: `${height}px` }}
      className={twMerge(
        `absolute inset-0 top-full md:hidden bg-black/70`,
        !isMenuOpen && 'hidden',
      )}
    >
      <div
        className="h-[calc(100%-1rem)] w-4/5 bg-white"
        onClick={(e) => e.stopPropagation()}
      >
        <MobileHeaderControls onClose={onClose} />
        <div className={twMerge(HEADER_CONTROLS_HEIGHT, 'overflow-auto')}>
          <MobileNavigationItems level={0} items={items} />
        </div>
      </div>
    </div>
  )
}
