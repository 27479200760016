import { postSearchAnalyticsData } from '@/api'
import type { AutocompleteState, Item, SearchState } from '../types'
import type { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime'

export function getNextFocusIndex(state: AutocompleteState): number {
  const nextIndex = state.focusedIndex + 1

  if (!state.focusedItem || nextIndex >= state.focusedList.length) {
    return 0
  }

  return nextIndex
}

export function getPreviousFocusIndex(state: AutocompleteState): number {
  const prevIndex = state.focusedIndex - 1

  if (!state.focusedItem || prevIndex < 0) {
    return state.focusedList.length - 1
  }

  return prevIndex
}

export function getQueryUrl(query: string): string {
  return `/catalogsearch/result?q=${query}`
}

type SectionType = 'products' | 'brands' | 'categories' | 'popular'

/**
 * Checks if the given section should be displayed, considering the input length:
 * - "popular" -> 0 characters;
 * - "brands" & "categories" -> 1+ characters;
 * - "brands", "categories" & "products" -> 3+ characters;
 */
export function isPopupSectionVisible(
  section: SectionType,
  input: string,
): boolean {
  switch (section) {
    case 'products':
      return input.length >= 3
    case 'brands':
    case 'categories':
      return input.length >= 1
    case 'popular':
      return input.length === 0
    default:
      return false
  }
}

/**
 * While the search is "submitting", disable interactivity:
 * - type / re-submit
 * -> hide dialog
 * -> change search border color to primary
 * -> change the cursor to default instead of pointer for hover over submit button
 */
export function isSearchDisabled(searchState: SearchState) {
  return searchState === 'submitting'
}

/**
 * While the search is "fetching" or "submitting":
 * -> display loading indicator
 */
export function isSearchLoading(searchState: SearchState) {
  return searchState === 'fetching' || searchState === 'submitting'
}

type Navigate = {
  text?: string
  url?: string
  router: AppRouterInstance
}
export function navigate({ text, url, router }: Navigate): void {
  if (text?.length) {
    router.push(encodeURIComponent(getQueryUrl(text)))
    router.refresh()
  }

  if (url) {
    router.push(url)
    router.refresh()
  }
}

type TrackClickParams = {
  productsList: Item[]
  searchTime: number
  resultsTime: number
  text: string
}
export function trackClick({
  productsList,
  searchTime,
  resultsTime,
  text,
}: TrackClickParams) {
  return (product: Item) => {
    const clickTime = Date.now()
    const productPosition = productsList.findIndex((i) => i.url === product.url)

    if (productPosition === -1) {
      return
    }

    const productBefore: Item | undefined = productsList[productPosition - 1]
      ? productsList[productPosition]
      : undefined
    const productAfter: Item | undefined = productsList[productPosition + 1]
      ? productsList[productPosition]
      : undefined

    const eventDetails = {
      clicked_at: clickTime,
      result: {
        display_text: product.label,
        uri: product.url,
        serp_position: productPosition + 1,
      },
      result_before: productBefore
        ? {
            display_text: productBefore.label,
            uri: productBefore.url,
          }
        : undefined,
      result_after: productAfter
        ? {
            display_text: productAfter.label,
            uri: productAfter.url,
          }
        : undefined,
    }

    postSearchAnalyticsData({
      event: 'click',
      click: { ...eventDetails },
      offset: clickTime - resultsTime,
      query: {
        current_value: text,
        entered_at: searchTime,
      },
      serp: {
        displayed_at: resultsTime,
        results: productsList.map((product, index) => ({
          serp_position: index + 1,
          display_text: product.label,
          uri: product.url,
        })),
        search_query: text,
      },
    })
  }
}

type TrackSearchParams = {
  searchTime: number
  text: string
}
export function trackSearch({ searchTime, text }: TrackSearchParams) {
  postSearchAnalyticsData({
    event: 'search',
    query: {
      current_value: text,
      entered_at: searchTime,
    },
  })
}

type TrackResultsParams = {
  productsList: Item[]
  searchTime: number
  resultsTime: number
  text: string
}
export function trackResults({
  productsList,
  searchTime,
  resultsTime,
  text,
}: TrackResultsParams) {
  postSearchAnalyticsData({
    event: 'results',
    query: {
      current_value: text,
      entered_at: searchTime,
    },
    serp: {
      displayed_at: resultsTime,
      search_query: text,
      results: productsList.map((product, index) => ({
        serp_position: index + 1,
        display_text: product.label,
        uri: product.url,
      })),
    },
    offset: resultsTime - searchTime,
  })
}
