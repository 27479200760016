'use cliet'
import React from 'react'
import { usePathname } from 'next/navigation'

import { isActiveChild, useIsActiveCategory } from '../../utils'
import { Text } from '@/components/text'
import { HeaderNavItem } from '../navigation-types'

interface CategoryTextProps {
  item: HeaderNavItem
}

export default function CategoryText({ item }: CategoryTextProps) {
  const { isActiveCategory } = useIsActiveCategory()
  const pathname = usePathname()
  const isAnyChildCategoryActive = isActiveChild(pathname, item.items)
  return (
    <Text
      className="lg:text-lg"
      case="upper"
      weight="bold"
      color={
        isActiveCategory(item) || isAnyChildCategoryActive ? 'red' : 'black'
      }
    >
      {item.label}
    </Text>
  )
}
