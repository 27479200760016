import { memo } from 'react'

import type { Item } from '../types'

type Props = {
  title: string
  items: Item[]
  itemRenderer: (item: Item) => React.ReactElement
}

function AutocompletePopupContent({ title, items, itemRenderer }: Props) {
  if (!items.length) {
    return null
  }

  return (
    <>
      <div className="text-xs font-bold uppercase py-1">{title}</div>
      <ul role="listbox">{items.map(itemRenderer)}</ul>
    </>
  )
}

export default memo(AutocompletePopupContent)
