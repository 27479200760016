import * as React from 'react'

import type { SVGProps } from 'react'

const SvgStar = ({
  partialStarValue,
  ...props
}: SVGProps<SVGSVGElement> & { partialStarValue?: number | false }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
      {...props}
    >
      {partialStarValue && (
        <defs>
          <linearGradient id="grad">
            {/* offset set up to percentage of the stop color: secondary */}
            <stop offset={`${partialStarValue}%`} stopColor="#ff4100" />
            {/* offset set up to percentage of the stop color: grey-300 */}
            <stop
              offset={`${partialStarValue}%`}
              stopColor="#959595"
              stopOpacity="1"
            />
          </linearGradient>
        </defs>
      )}
      <path
        d="M804.645 444.599c-1.499-4.681-5.888-7.899-10.789-7.899h-206.958l-64.073-196.754c-1.536-4.645-5.888-7.826-10.825-7.826-4.901 0-9.289 3.182-10.825 7.863l-64.439 196.754h-206.592c-4.901 0-9.289 3.218-10.825 7.863-1.499 4.681 0.146 9.874 4.096 12.763l167.205 121.783-64.439 197.851c-1.536 4.681 0.146 9.838 4.133 12.727 3.95 2.889 9.399 2.889 13.349 0l168.338-122.185 167.936 122.149c2.011 1.463 4.315 2.231 6.693 2.231s4.681-0.768 6.693-2.231c4.023-2.889 5.669-8.009 4.133-12.727l-64.439-197.851 167.57-121.783c3.95-2.889 5.632-8.046 4.059-12.727z"
        fill={partialStarValue ? `url(#grad)` : undefined}
      />
    </svg>
  )
}
export default SvgStar
