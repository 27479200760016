import React, { FC, PropsWithChildren, useMemo } from 'react'

import { Flex } from '@/components/flex'

interface PopupContentProps {
  parentClientRect?: DOMRect
}

export const PopupContent: FC<PropsWithChildren<PopupContentProps>> = (
  props,
) => {
  const { children, parentClientRect } = props
  // find the position of the caret, it should be in the middle of the parent element
  const caretPosition = useMemo(() => {
    if (!parentClientRect) {
      return 0
    }
    return parentClientRect.width / 2
  }, [parentClientRect])

  return (
    <Flex
      variant="bordered"
      className="absolute mt-2 right-0 min-w-full bg-white z-10"
    >
      {/* this box provides a hover surface when in openOnHover mode */}
      <Flex className="absolute -mt-4 w-full h-4 z-10" />

      <Flex
        variant="bordered"
        className="bg-white absolute"
        style={{
          width: 14,
          height: 14,
          right: caretPosition,
          top: -7,
          borderTopColor: 'white',
          borderRightColor: 'white',
          transform: 'translate(50%) rotate(-225deg)',
        }}
      />

      <Flex className="z-10">{children}</Flex>
    </Flex>
  )
}
