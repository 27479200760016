'use client'

import { useRef, useEffect, useState } from 'react'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/navigation'

import AutocompleteInput from './input/AutocompleteInput'
import AutocompletePopup from './popup/AutocompletePopup'
import AutocompletePopupContent from './popup/AutocompletePopupContent'
import AutocompletePopupItem from './popup/AutocompletePopupItem'
import {
  isSearchDisabled,
  isPopupSectionVisible,
  getNextFocusIndex,
  getPreviousFocusIndex,
  navigate,
} from './helpers/autocomplete.utils'
import { useKeyboardNav } from './hooks/useKeyboardNav'
import { useClickOutside } from './hooks/useClickOutside'
import { useSearch } from './hooks/useSearch'
import { useHeaderContext } from '../header-context'
import type { Item, AutocompleteState } from './types'

type AutocompleteProps = {
  isDesktop?: boolean
  popularSearch: Item[]
}

export function Autocomplete({ isDesktop, popularSearch }: AutocompleteProps) {
  const router = useRouter()
  const t = useTranslations('Header')
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const [state, setState] = useState<AutocompleteState>({
    state: 'initial',
    input: '',
    isFocused: false,
    focusedIndex: 0,
    focusedItem: undefined,
    focusedList: [],
    popupSections: [
      {
        title: t('search.menu.popular'),
        list: popularSearch,
      },
    ],
  })
  const prevInputRef = useRef('')
  const {
    state: { isMobileSearch },
  } = useHeaderContext()
  const { isLoading, products, categories, brands, trackClick } = useSearch({
    text: state.input,
    enabled:
      state.isFocused && !!state.input && state.input !== prevInputRef.current,
  })

  const reset = (currentState?: Partial<AutocompleteState>) => {
    prevInputRef.current = state.input
    setState((prevState) => ({
      ...prevState,
      state: 'initial',
      focusedItem: undefined,
      focusedIndex: 0,
      ...currentState,
    }))
  }

  const handleFocus = (isFocused: boolean) => {
    if (isFocused === state.isFocused) {
      return
    }

    reset({ isFocused })
  }

  const handleChange = (input: string) => {
    if (state.input === input) {
      return
    }

    if (!input) {
      reset({ input: '' })
      return
    }

    setState((prevState) => ({
      ...prevState,
      focusedItem: undefined,
      focusedIndex: 0,
      state: 'fetching',
      input,
    }))
  }

  const handleSubmit = (focusedPopupItem?: Item) => {
    const submitState: Partial<AutocompleteState> = {
      isFocused: false,
      focusedIndex: 0,
      focusedItem: undefined,
      state: 'submitting',
    }

    prevInputRef.current = state.input

    if (focusedPopupItem) {
      setState((prevState) => ({
        ...prevState,
        ...submitState,
        focusedItem: focusedPopupItem,
        input: focusedPopupItem.label,
      }))
      trackClick(focusedPopupItem)
      navigate({
        url: focusedPopupItem.url,
        router,
      })
      return
    }

    if (!state.input || isSearchDisabled(state.state)) {
      return
    }

    setState((prevState) => ({
      ...prevState,
      ...submitState,
    }))
    navigate({ text: state.input, router })
  }

  useClickOutside({
    ref: wrapperRef,
    enabled: state.isFocused,
    onClickOutside: () => {
      handleFocus(false)
    },
  })

  useKeyboardNav({
    enabled: state.isFocused,
    onArrowDown: () => {
      const i = getNextFocusIndex(state)

      setState((prevState) => ({
        ...prevState,
        focusedIndex: i,
        focusedItem: state.focusedList[i],
      }))
    },
    onArrowUp: () => {
      const i = getPreviousFocusIndex(state)

      setState((prevState) => ({
        ...prevState,
        focusedIndex: i,
        focusedItem: state.focusedList[i],
      }))
    },
    onEnter: () => {
      handleSubmit(state.focusedItem)
    },
    onEsc: () => {
      handleFocus(false)
    },
  })

  useEffect(() => {
    if (!isLoading) {
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  useEffect(() => {
    const input = prevInputRef.current
    const isPopularVisible = isPopupSectionVisible('popular', input)
    const isProductsVisible = isPopupSectionVisible('products', input)
    const isCategoriesVisible = isPopupSectionVisible('categories', input)
    const isBrandsVisible = isPopupSectionVisible('brands', input)

    setState((prevState) => ({
      ...prevState,
      focusedList: [
        ...(isPopularVisible ? popularSearch : []),
        ...(isProductsVisible ? products : []),
        ...(isCategoriesVisible ? categories : []),
        ...(isBrandsVisible ? brands : []),
      ],
      popupSections: [
        {
          title: t('search.menu.popular'),
          list: isPopularVisible ? popularSearch : [],
        },
        {
          title: t('search.menu.product'),
          list: isProductsVisible ? products : [],
        },
        {
          title: t('search.menu.categories'),
          list: isCategoriesVisible ? categories : [],
        },
        {
          title: t('search.menu.brands'),
          list: isBrandsVisible ? brands : [],
        },
      ],
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popularSearch, products, categories, brands])

  return (
    <div
      ref={wrapperRef}
      className="relative flex w-full py-1 transition-all aria-hidden:hidden data-[ui=desktop]:max-w-[295px] md:hidden data-[ui=desktop]:md:flex data-[ui=desktop]:lg:max-w-[464px]"
      aria-hidden={!isDesktop && !isMobileSearch}
      data-ui={isDesktop ? 'desktop' : ''}
    >
      <AutocompleteInput
        focusedPopupItem={state.focusedItem}
        isFocused={state.isFocused}
        state={state.state}
        onFocus={handleFocus}
        onChange={handleChange}
        onSubmit={handleSubmit}
      />
      <AutocompletePopup
        isOpen={
          state.isFocused &&
          !isSearchDisabled(state.state) &&
          !!state.focusedList?.length
        }
      >
        {state.popupSections.map((section, index) => {
          return (
            <AutocompletePopupContent
              key={index}
              title={section.title}
              items={section.list}
              itemRenderer={(item: Item) => (
                <AutocompletePopupItem
                  key={item.id}
                  query={prevInputRef.current}
                  isFocused={item.id === state.focusedItem?.id}
                  onSubmit={handleSubmit}
                  {...item}
                />
              )}
            />
          )
        })}
      </AutocompletePopup>
    </div>
  )
}
